export const fields = [
    { key: 'index', label: "No", _style:'width:10px'},
    { key: 'select', label: "", _style:'idth:10px' },
    { key: 'no_complaint', label: "No Pengaduan", _style:'min-width:100px' },
    { key: 'tanggal', label: "Tanggal", _style:'min-width:100px' },
    { key: 'address', label: "Alamat", _style:'min-width:100px' },
    { key: 'kecamatan', label: "Kecamatan", _style:'min-width:100px' },
    { key: 'desa', label: "Desa", _style:'min-width:100px' },
    { key: 'remark', label: "Catatan", _style:'min-width:100px' },
    { key: 'status', label: "Status", _style:'min-width:100px' },
    { key: 'priority', label: "Prioritas", _style:'min-width:100px' },
    { key: 'report_source_name', label: "Sumber", _style:'min-width:100px' },
    { key: 'reporter', label: "Pelapor", _style:'min-width:100px' },
    { key: 'phone_reporter', label: "No. Telepon Pelapor", _style:'min-width:100px' },
    { key: 'user', label: "Dibuat", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
