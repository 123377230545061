<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <button class="btn btn-sm btn-primary ml-2" @click="addComplaint()">
                Tambah Pengaduan
              </button>
             
              <label class="m-1 ml-3" for="">Select All : </label>
              <input
                type="checkbox"
                v-model="isSelectedAll"
                @change="checkAll()"
              />

              <button v-if="selectedItems.length > 0" class="btn btn-sm btn-info ml-2" @click="addAssigment()">
                Buat Surat Tugas
              </button>

              <router-link
                :to="{
                  path: '/pengaduan-pdf',
                  query: { fromDate: params.fromDate, toDate: params.toDate }
                }"
              >
                <button class="btn btn-sm btn-info ml-2">
                  Export Data
                </button>
              </router-link>
              

            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 150px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <input
                type="date"
                v-model="params.fromDate"
                style="max-width: 150px"
                class="form-control form-control-sm mx-2"
                placeholder="Tanggal Awal"
              />
              <input
                type="date"
                v-model="params.toDate"
                style="max-width: 150px"
                class="form-control form-control-sm mx-2"
                placeholder="Tanggal Akhir"
              />
              <select
                class="custom-select"
                style="max-width: 150px"
                id="inputGroupSelect01"
                v-model="params.kecamatan"
                @change="getData"
              >
                <option selected value="">Semua Kecamatan</option>
                <option v-for="region in regions" :key="region.id" :value="region.id">{{ region.name }}</option>
              </select>
              <select
                class="custom-select ml-2"
                style="max-width: 150px"
                id="inputGroupSelect01"
                v-model="params.status"
                @change="getData"
              >
                <option selected value="0">Baru</option>
                <option value="1">Proses</option>
                <option value="2">Selesai</option>
              </select>
              <button @click="search()" class="btn btn-sm btn-success mx-2">
                Cari
              </button>

            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData()"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <v-container>
    
          <v-col>
              <!-- we use this wrapper to show the second scroll above of table -->
              <div id="wrapper2">
                <div id="div2" class="width-scroll" >
                </div>
              </div>
          </v-col>
            
          <v-col>
          <CDataTable
            class="table-striped"
            :items="computedItems"
            :fields="fields"
            sorter
            style="max-height: 300px; overflow: auto;"
          >
          <template #select="{ item}">
            <td class="py-2">
              <input 
                  type="checkbox"
                  :disabled="item.status != 'BARU'"
                  @change="check(item)"
                  v-model="item.select"
                />
            </td>
          </template>
          <template #index="{item, index}">
            <td>{{index + 1}}</td>
          </template>
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  @click="edit(item)"
                  class="mr-2"
                  color="warning"
                  square
                  size="sm"
                >
                  Edit
                </CButton>
                
                <CButton
                  v-if="item.id != complaint.id"
                  @click="hapus(item)"
                  color="danger"
                  square
                  size="sm"
                >
                  Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
          <pagination
            v-if="total !== items.length"
            v-model="page"
            :records="total"
            :per-page="50"
            @paginate="pagination"
          />
          </v-col>
        </v-container>
      </CCardBody>
    </CCard>

    <CModal
      size="xl"
      :title="isUpdate ? 'Edit Pengaduan' : 'Tambah Pengaduan'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
      :closeOnBackdrop="false"
    >
    <div class="row">
        <div class="col-6">
          <CInput
            v-model="form.no_complaint"
            label="No Pengaduan"
            disabled
            placeholder="Autonumber"
          />
          <CInput
            v-model="form.address"
            label="Alamat"
            placeholder="ketik disini"
          />
          <label for="kecamatan">Kecamatan</label>
          <v-select
            class="mb-3"
            id="kecamatan"
            v-model="form.region"
            placeholder="Pilih Kecamatan"
            :options="computedRegion"
          ></v-select>
          <label for="report">Sumber Pengaduan</label>
          <select
            class="custom-select"
            id="report"
            v-model="form.report_source"
          >
            <!-- <option selected>Pilih...</option> -->
            <option value="1">Medsos</option>
            <option value="2">Call Center</option>
            <option value="3">Pengaduan Masyarakat</option>
            <option value="4">Surat</option>
            <option value="5">Pengaduan Langsung</option>
          </select>
          <CInput
            class="mt-2"
            v-model="form.tanggal"
            type="date"
            label="Tanggal"
            placeholder="ketik disini"
          />
        </div>
        <div class="col-6">
          <CInput
            v-model="form.remark"
            label="Catatan"
            placeholder="ketik disini"
          />
          <label for="priority">Priority</label>
          <select
            class="custom-select mb-3"
            id="priority"
            v-model="form.priority"
          >
            <!-- <option selected>Pilih...</option> -->
            <option value="Urgent">Urgent</option>
            <option value="Biasa">Biasa</option>
          </select>
          <CInput
            v-model="form.desa"
            label="Desa"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.reporter"
            label="Pelapor"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.phone_reporter"
            label="No Telepon Pelapor"
            placeholder="ketik disini"
          />
        </div>
      </div>
      <div class="row">
          <div class="col-12">
            
      <div class="row">
        <div class="col text-center">
          <div
            v-if="imageList.length <= 3"
            class="card border d-flex justify-content-center"
            style="height: 250px"
          >
            <div class="align-self-center mb-3">
              (MAKS 4)
              <br />
              <img src="@/assets/upload.png" width="100px" alt="" />
            </div>
            <button
              class="btn btn-sm btn-primary align-self-center"
              style="width: 15%"
              @click="upload"
            >
              Upload Disini
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="div">
            <!-- <label class="form-label" for="newData.file">Upload File</label> -->
            <input
              hidden
              type="file"
              class="form-control"
              ref="uploadField"
              @change="selectFile"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3" v-for="(item, index) in imageList" :key="item">
          <CCardLink :href="item" target="_blank" class="">
            <CCardImg
              :src="item"
              style="object-fit: cover"
              height="auto"
              variant="full"
            />
          </CCardLink>

          <button
            class="btn btn-danger btn-sm btn-block mt-2"
            @click="deleteImage('update', index)"
          >
            Hapus
          </button>
          <br />
        </div>
      </div>
          </div>
        </div>
      <template slot="footer">
        <div>
          <button @click="createModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update
          </button>
        </div>
      </template>
    </CModal>

    <CModal
      size="xl"
      :title="isUpdate ? 'Edit Penugasan' : 'Tambah Penugasan'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModalPenugasan"
      :closeOnBackdrop="false"
    >
      <div class="row">
        <div class="col-6">
          <CInput
            v-model="form_assignment.no_assigment"
            label="No Surat Tugas"
            disabled
            placeholder="Autonumber"
          />
          <CInput
            v-model="form_assignment.assignment_date"
            label="Date"
            type="date"
            placeholder="ketik disini"
          />
        </div>
        <div class="col-6">
          <CInput
            v-model="form_assignment.remark"
            label="Catatan"
            placeholder="ketik disini"
          />
          <label for="team">Team</label>
          <v-select
            class="mb-3"
            id="team"
            v-model="form_assignment.team_name"
            placeholder="Pilih Team"
            :options="computedTeam"
            @input="onTeamSelected"
            label="label"
          ></v-select>
          
          <CInput
            v-model="form_assignment.team_id"
            placeholder="ketik disini"
            style="display: none;"
          />
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-6">
          <p>Daftar Petugas</p>
        </div>
        <div class="col-4">
          <v-select
            placeholder="Pilih Petugas"
            :options="computedPetugas.filter((n) => n)"
            v-model="selectedPetugas"
          ></v-select>
        </div>
        <div class="col">
          <button @click="add" class="btn btn-sm btn-primary">
              + Tambah Petugas
          </button>
        </div>
      </div>
      
      
      <table class="table">
        <thead>
          <tr>
            <td>No.</td>
            <td>Nama</td>
            <td>Jabatan</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          <tr  v-for="(item, index) in team.petugas" :key="item.id">
            <td>{{ index+1 }}.</td>
            <td>{{ item.full_name }}</td>
            <td>{{ item.position }}</td>
            <td>
              <CButton
                @click="deletePetugas(index)"
                color="danger"
                square
                size="sm"
              >
                Hapus
              </CButton></td>
            
          </tr>
        </tbody>
      </table>

      <p>Daftar Pengaduan</p>
      <table class="table">
        <thead>
          <tr>
            <td>No.</td>
            <td>No. Pengaduan</td>
            <td>Kecamatan</td>
            <td>Alamat</td>
          </tr>
        </thead>
        <tbody>
          <tr  v-for="(item, index) in selectedItems" :key="item.no_complaint">
            <td>{{ index+1 }}.</td>
            <td>{{ item.no_complaint }}</td>
            <td>{{ item.region? item.region.name : '-' }}</td>
            <td>{{ item.address }}</td>
          </tr>
        </tbody>
      </table>
            
      <template slot="footer">
        <div>
          <button @click="createModalPenugasan = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submitPenugasan" v-if="!isUpdate" class="btn btn-primary">
            Tambah
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/complaint"
import "firebase/auth"
import { uploadImage } from "@/utils/fileUpload";

export default {
  data() {
    return {
      createModal: false,
      createModalPenugasan: false,
      assignedIds: {}, 
      nextId: 1 ,
      fields: [],
      isUpdate: false,
      items: [],
      regions: [],
      teams:[],
      petugas:[],
      complaint: [], 
      team_region: [],
      imageList: [],
      selectedPetugas: null,
      form: {
        report_source : "1",
        created_by : 1,
      },
      form_assignment: {},
      params: {
        sorttype: "desc",
        sortby: "date",
        row: 50,
        kecamatan: "",
        page: 1,
        keyword: "",
      },
      params2: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      selectedItems: [],
      isSelectedAll: false,
      selectedAction: 0,
      page: 1,
      total: 0,
      to: 0,
      isSearching: false,
      searchOn: "",
      modalPassword: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: "",
      verificators: [{ value: 0, label: "-" }],
      team: {},
    }
  },
  methods: {
    add() {
      if (this.selectedPetugas) {
        if (!this.team.petugas.some(petugas => petugas.full_name === this.selectedPetugas.label)) {
          this.team.petugas.push({
          id: this.selectedPetugas.id, 
          full_name: this.selectedPetugas.label, 
          position: this.selectedPetugas.position,
        })
        }else{
          alert("Data Sudah Ada !!")
        } 
      } else {
        alert("Tolong isi Petugas Terlebih Dahulu !!")
      }
    },
    deletePetugas(index) {
      (this.team.petugas.splice(index, 1))
    },
    onTeamSelected(selectedTeamId) {
        const selectedTeam = this.teams.find(team => team.no === selectedTeamId);
        
        this.form_assignment.team_id = selectedTeamId.value; 
        this.form_assignment.team_name = selectedTeamId.label; 
        this.selectedItems[0].region_id = selectedTeamId.no; 
        this.addAssigment();
    },
    selectImage(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.form.profile_picture = resp;  
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    getNameVerificator(id) {
      let data = this.verificators.find((item) => {
        return item.value == id
      })
      if (data) {
        return data.label
      } else {
        return "BUKAN VERIFIKATOR"
      }
    },
    search() {
      if (this.params.keyword != "" || this.params.fromDate != ""  || this.params.toDate != "") {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },
    searchOff() {
      this.isSearching = false
      this.params.keyword = ""
      this.getData()
    },
    submitPenugasan() {
      var loading = this.$loading.show()

      this.form_assignment.assignment_complaints = [];
      this.selectedItems.forEach(element => {
        this.form_assignment.assignment_complaints.push({
          complaint_id: element.id
        })
      });

      this.form_assignment.petugas = [];
      this.team.petugas.forEach(element => {
        this.form_assignment.petugas.push({
          petugas_id: element.id
        })
      });
      
      this.$store
        .dispatch("assignment/addAssignment", this.form_assignment)
        .then(() => {
          this.$toast.success("Berhasil menambahkan Item")
          loading.hide()
          this.createModalPenugasan = false;
          this.form = {}
          this.$router.replace({ path: "transaksi-item-penugasan" });
        })
        .catch((e) => {
          console.error(e)
          this.$toast.success(e)
          loading.hide()
        })
    },
    submit() {
      var loading = this.$loading.show()
      this.form.created_by = 1;
      this.form.region_id = this.form.region ? this.form.region.value : null
      this.form.complaint_documents = [];
      this.imageList.forEach(element => {
        this.form.complaint_documents.push({
          url_file: element,
          type: "image",
          type_user: 0
        })
      });
      
      this.$store
        .dispatch("complaint/addComplaint", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan Item")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          console.error(e)
          this.$toast.success(e)
          loading.hide()
        })
    },
    edit(item) {
      this.form = item
      this.isUpdate = true
      this.createModal = true
      this.imageList = this.extractGallery(item.complaint_documents);
    },
    update() {
      var loading = this.$loading.show()
      this.form.created_by = 1;
      this.form.region_id = this.form.region ? this.form.region.value : null;
      const date = new Date(this.form.tanggal);
      const formattedDate = date.toISOString().split('T')[0]; // Extract date part in 'YYYY-MM-DD' format
      this.form.tanggal = formattedDate;
      console.log(this.form);
      this.form.complaint_documents = [];
      this.imageList.forEach(element => {
        this.form.complaint_documents.push({
          url_file: element,
          type: "image",
          type_user: 0
        })
      });
      this.$store
        .dispatch("complaint/updateComplaint", { id: this.form.id, data: this.form })
        .then(() => {
          this.$toast.success("Berhasil merubah data complaint")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("complaint/deleteComplaint", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data complaint")
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("complaint/getComplaint", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total
          this.to = resp.data.to;
          console.log(this.item)

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element)
            } else {
              element.select = false
            }
          })
          loading.hide()

          this.getDataRegion() 
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataUser() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("user/getUser", params)
        .then((resp) => {
          this.petugas = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataRegion() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "desc",
        sortby: "created_at",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("kecamatan/getKecamatan", params)
        .then((resp) => {
          this.regions = resp.data.data
          console.log(regions)
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    addComplaint() {
      this.isUpdate = false
      this.form = {}
      this.imageList = [];
      this.createModal = true
    },
    addAssigment() {
      // ambil teknisi by team
      let regions = [this.selectedItems[0].region_id];
      this.getTeamByKecamatan(regions)
    },
    getTeamByKecamatan(regions) {
      var loading = this.$loading.show()
      this.$store
        .dispatch("team/getTeamByKecamatan", regions)
        .then((resp) => {
          console.log(resp)
          if (resp.status == 500) {
            this.$toast.error(resp.message);
            this.createModalPenugasan = false
          } else {
            this.isUpdate = false
            this.form_assignment = {
              assignment_complaints: []
            }
            this.createModalPenugasan = true
            this.team = resp.data[0]
            this.form_assignment.team_id = this.team.id;
            this.form_assignment.team_name = this.team.name;
          }
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataTeam() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("team/getTeam", this.params2)
        .then((resp) => {
          this.teams = resp.data.data.map((team, index) => {
            return {
              ...team,
              sequence: index + 1  
            };
          });
          this.jmlTeam = this.teams.length
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },
    check(item) {
      if (item.select) {
        this.selectedItems.push(item);
      } else {
        const index = this.selectedItems.indexOf(item);
        this.selectedItems.splice(index, 1);
      }
    },
    checkAll() {
      this.getData();
    },
    deleteImage(state, index) {
      this.imageList.splice(index, 1);
    },
    selectFile(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.imageList.push(resp);
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    upload() {
      this.$refs.uploadField.click();
    },
    extractGallery(item) {
      let data = [];
      item.forEach(element => {
        data.push(element.url_file);
      });
      return data;
    },
  },
  computed: {  
    computedItems() {
      return this.items.map((item, index) => {
        return {
          // index: (this.to - this.items.length) + index+1 + '.',
          ...item,
          user: item.user ? item.user.full_name : "-",
          status: item.status == 0 ? "BARU" : "PROSES",
          report_source_name: 
            item.report_source == "1" ? "MEDSOS" :
            item.report_source == "2" ? "Call Center" :
            item.report_source == "3" ? "Pengduan Masyarakat" :
            item.report_source == "4" ? "Surat" :
            item.report_source == "5" ? "Pengaduan Langsung" :
            item.report_source,
          kecamatan: item.region ? item.region.name : "Tidak ada",
          desa: item.desa ? item.desa : "Tidak ada",
          tanggal: item.tanggal ? this.$moment(item.tanggal).format("Do MMMM YYYY"): this.$moment(item.created_at).format("Do MMMM YYYY"),
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
        }
      })
    },
    computedRegion() {
      if (this.form.region_id) {
        let region = {
          value: this.form.region_id,
          label: this.regions.find((element) => {
            return element.id == this.form.region_id
          }).name,
        }
        this.form.region = region
      }
      return this.regions.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
    },
    computedTeam() {
      return this.teams.map(item => {
            return {
                no: item.regions[0].id,
                value: item.id, 
                label: item.name,
            };
        });
    },
    computedPetugas() {
      return this.petugas.map((item) => {
        if (item.type == 2)
        return {
            value: item.id,  
            label: item.full_name,
            id: item.id,  
            position: item.position,
        };
      });
    },
  },
  mounted() {
    this.getData()
  },
  created() {
    this.getDataRegion() 
    this.getDataUser()
    this.getDataTeam()
    this.fields = data.fields

    // let wrapper_1 = document.querySelector('.table-striped .table-responsive');
    this.$nextTick(() => {
      let wrapper_1 = document.querySelector('.table-striped .table-responsive');
      let wrapper_2 = document.querySelector('#wrapper2');
      if (wrapper_1) {
        // Element found, you can now work with it
        wrapper_1.onscroll = function() {
          wrapper_2.scrollLeft = wrapper_1.scrollLeft;
        };
        wrapper_2.onscroll = function() {
          wrapper_1.scrollLeft = wrapper_2.scrollLeft;
        };
      } else {
        // Element not found, log an error or handle the situation accordingly
        console.error("Element not found");
      }
    });
  },
}
</script>

<style>

/* This is the size of the path of the scrollbar */
#wrapper2{
      width: 100%;
      overflow-x: scroll; 
      overflow-y:hidden;
  }

/* This div allow to make the scroll function and show the scrollbar */
  #div2 {
      height: 1px;
      overflow: scroll;
  }

  /* This is the size of the scrollbar */
  .width-scroll{
    width: 1500px;
  }

  @media only screen and (max-width: 767px) {

  /* Adjust styles for mobile display here */
  #wrapper2 {
    width: 100%;
    overflow-x: auto;
  }

  #div2 {
    height: 7px;
    overflow: scroll;
  }

  .width-scroll {
    width: 1500px;
  }

  }

</style>